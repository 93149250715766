export type ActionType = {
  type: string;
  data?: any;
  filters?: any;
  columns?: string[];
};

export const FAULTS_REPORT = {
  FILTER: 'FAULTS_REPORT_FILTER',
  RESET_FILTERS: 'FAULTS_REPORT_RESET_FILTERS',
  SET_VISIBLE_COLUMNS: 'FAULTS_REPORT_SET_VISIBLE_COLUMNS',
};

export function filter(filters: any) {
  return {
    type: FAULTS_REPORT.FILTER,
    filters,
  };
}

export function setVisibleColumns(columns: string[]) {
  return {
    type: FAULTS_REPORT.SET_VISIBLE_COLUMNS,
    columns,
  };
}
