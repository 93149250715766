import { useQuery } from '@apollo/client';
import { getSharingListContextQuery, GetSharingListContextQuery } from './gql';

export const useSharingListContext = () => {
  const { data, loading } = useQuery<GetSharingListContextQuery>(
    getSharingListContextQuery,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const { hasIssuedSharings, hasReceivedSharings, isMigratingFromV1 } =
    data?.viewer.sharing || {};

  return { hasIssuedSharings, hasReceivedSharings, isMigratingFromV1, loading };
};
