import colors from 'constants/colors';
import { InfoIcon } from '../Icons/InfoIcon';
import { InfosContainer, InfosIconContainer, InfosIconText } from './index.css';

export type CallOutProps = {
  text: string | JSX.Element;
  marginBottom?: number;
  marginTop?: number;
  fontSize?: number;
  fullWidth?: boolean;
  customIcon?: JSX.Element;
};

export const CallOut = ({
  text,
  marginBottom,
  marginTop,
  fontSize,
  fullWidth = false,
  customIcon,
}: CallOutProps) => (
  <InfosContainer
    marginBottom={marginBottom}
    marginTop={marginTop}
    fullWidth={fullWidth}
  >
    <InfosIconContainer>
      {!customIcon ? <InfoIcon color={colors.gray850} /> : customIcon}
    </InfosIconContainer>
    <InfosIconText fontSize={fontSize}>{text}</InfosIconText>
  </InfosContainer>
);
