import styled from 'styled-components';

import colors from 'constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

export const Container = styled.div<{ noShadow?: boolean; noBorder?: boolean }>`
  background-color: ${colors.white};
  border-radius: 5px;
  ${(props) => !props.noBorder && `border: 0.5px solid ${colors.gray200}`};
  ${(props) => !props.noShadow && 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1)'};
  display: block;
  width: 100%;
`;

export const TopBar = styled.div`
  display: flex;
  min-height: 31px;
  margin-bottom: 20px;
`;

export const LoadingOverlay = styled.div<{ active: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: ${(props) => (props.active ? 2 : -1)};
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: none;

  span {
    position: absolute;
    top: 50%;
    left: 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    text-align: center;
    width: 100%;
    transform: ${(props) =>
      props.active ? 'translateY(50%)' : 'translateY(-52%)'};
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`;

export const TableContainer = styled.div`
  color: ${colors.gray850};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const TableWrapper = styled.div<{ disablePointer?: boolean }>`
  overflow-x: auto;
  ${(props) => (props.disablePointer ? 'pointer-events: none;' : '')}
`;

export const StyledTable = styled.table`
  align-items: stretch;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
`;

export const THead = styled.thead`
  display: flex;
  flex-direction: column;
  height: 40px;
  user-select: none;
`;

export const TBody = styled.tbody`
  display: flex;
  flex-direction: column;
  min-height: 60px;
`;

export const Tr = styled.tr<{
  cursorPointer?: boolean;
  rowHeight?: number;
  light?: boolean;
  isHighlighted?: boolean;
}>`
  border-bottom: 1px solid ${colors.gray200};
  min-width: 100%;
  display: inline-flex;
  flex: 1 0 auto;
  min-height: 40px;
  height: ${(props) => (props.rowHeight ? `${props.rowHeight}px` : '')};
  justify-content: ${(props) => (props.light ? 'space-between' : 'flex-start')};
  ${(props) => props.isHighlighted && `background: ${colors.gray50}`};

  ${THead} & {
    background-color: ${colors.gray50};
  }

  :hover {
    background: ${colors.gray50};

    ${(props) => (props.cursorPointer ? 'cursor: pointer;' : '')}

    .visible-on-row-hover {
      visibility: visible;
    }
  }
`;

export const ThContent = styled.div<{
  canSort?: boolean;
  isSorted?: boolean;
}>`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  line-height: 18px;
  padding: 5px;
  position: relative;
  transition: background 200ms ease-in 0s;
  width: 100%;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${(props) =>
    props.canSort &&
    `
    :hover {
      cursor: pointer;
      background-color: rgba(45,47,48,0.08);
    }

    :active {
      background-color: rgba(45,47,48,0.16);
    }
  `}

  ${(props) => props.isSorted && 'padding-right: 20px;'}
`;

export const Th = styled.th<{ light?: boolean; highlight?: boolean }>`
  align-items: center;
  border-right: ${(props) => (props.light ? 'none' : '1px solid #e4e8eb')};
  display: flex;
  flex: none !important;
  padding: 0;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) =>
    props.highlight &&
    `
    color: ${colors.white};
    background-color: ${colors.blue400};
  `}

  &:first-child ${ThContent} {
    padding-left: 24px;
  }
  &:last-child ${ThContent} {
    padding-right: 24px;
  }
`;

export const Td = styled.td<{
  light?: boolean;
  highlight?: boolean;
  noPadding?: boolean;
}>`
  align-items: center;
  border-right: ${(props) => (props.light ? 'none' : '1px solid #e4e8eb')};
  display: flex !important;
  flex: none !important;
  ${(props) => !props.noPadding && 'padding: 4px 5px;'}
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) => props.highlight && `background-color: #F5F6FF;`}

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;

export const TdContent = styled.div<{}>`
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Resizer = styled.div<{ isResizing?: boolean }>`
  ${(props) => props.isResizing && `background-color: ${colors.cyan400};`}
  position: absolute;
  right: -3px !important;
  top: 0;
  bottom: 0;
  touch-action: none;
  transition: background 200ms ease-out;
  width: 5px;
  z-index: 10;
  cursor: col-resize;
  &:hover {
    background-color: ${colors.cyan400};
  }
`;

export const SortIcon = styled.div<{ isDescending?: boolean }>`
  position: absolute;
  right: 5px;
  transition: transform 200ms linear;
  ${(props) => props.isDescending && 'transform: rotate(-180deg)'};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const HeaderComponentWrapper = styled.div`
  margin: 16px;
  width: calc(100% - 32px);
  margin-bottom: 8px;
`;
