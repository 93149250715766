import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderGroup,
  TooltipTarget,
  useBlockLayout,
  useColumnOrder,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { ArrowUpIcon, InfoIcon } from 'modules/common-ui';
import { loadState, saveState } from 'utils/storage';

import { TableProps } from './types';
import { getColumnWidth } from './utils/columns';

import { ActionBar } from './components/ActionBar';
import { EmptyState } from './components/EmptyState';
import { Exporter } from './components/Exporter';
import { Pagination } from './components/Pagination';

import { CustomTooltip } from 'modules/analytics/components/common/CustomTooltip/CustomTooltip';
import styled from 'styled-components';
import {
  Container,
  HeaderComponentWrapper,
  LoadingOverlay,
  Resizer,
  SortIcon,
  StyledTable,
  TableContainer,
  TableWrapper,
  TBody,
  Td,
  TdContent,
  Th,
  ThContent,
  THead,
  TopBar,
  Tr,
  Wrapper,
} from './index.css';

const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

// eslint-disable-next-line max-statements, complexity
export const Table = <D extends object = {}>({
  // basic
  columns,
  data,
  id: tableId = 'tableV7',
  loading = false,
  testIdPrefix,
  title = '',
  subtitle,

  onRowClick,

  // custom
  readColumnsFromLocalStorage = true,
  defaultVisibleColumns = [],
  highlightColumns = [],
  displayActionBar = true,
  displayColumnToggler = true,
  onVisibleColumnsChange,
  light = false,
  noDataText = '',
  noDataButton,
  disableResizing = false,
  rowHeight,
  actions,
  selectedPeriodComponent,
  computeColumnsWidth = true,
  saveColumnsWidth = true,
  classNamePrefix = 'tableV7',
  saveVisibleColumns = true,
  selectedRow,
  onColumnVisibilityChange,
  onPageSizeChange,
  onSortChange,

  // manual
  currentPage = 1,
  defaultPageSize = 25,
  fetchData,
  pageCount: controlledPageCount,
  totalRows,

  // search
  displaySearch = true,
  onSearch,
  searchPlaceholder,
  tracking = '',

  // sort
  defaultSorted = [],
  disableSortBy = false,

  // export
  displayExport = false,
  exportButtonId = 'export-button',
  exportFilename = 'Export_',
  onExport,
  exporting = false,
  displayExportButtonAsAction = false,

  // filters
  filterOptions,
  clickable = false,

  // pagination
  disablePagination = false,

  // styles
  forceNoShadow = false,
  forceNoBorder = false,
  searchNoPaddingHorizontal = false,
  searchFullWidth = false,
  searchWidth = undefined,

  // header component (ex: fault onboarding banner)
  headerComponent = null,
}: TableProps<D>) => {
  const [t] = useTranslation('tableV7');
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const [savedColumnPreferences] = useState(() => {
    return (
      (readColumnsFromLocalStorage &&
        loadState(localStorage, `${tableId}__column_preferences`)) ||
      {}
    );
  });

  const {
    columnOrder: savedColumnOrder = undefined,
    visibleColumns: savedVisibleColumns = undefined,
    hiddenColumns: savedHiddenColumns = undefined,
    sortedColumn: savedSortedColumn = undefined,
  } = displayColumnToggler ? savedColumnPreferences : {};

  const { columnWidths: savedColumnWidths = undefined } = !disableResizing
    ? savedColumnPreferences
    : {};

  const [totalColumnsWidth, setTotalColumnsWidth] = useState(0);
  const [colWidths, setColWidths] = useState(savedColumnWidths);

  const colOrder =
    savedColumnOrder && savedColumnOrder.length
      ? savedColumnOrder
      : columns.map((c) => c.id);
  const colOrderStr = JSON.stringify(colOrder);

  let visibleCols: string[] = [];
  if (savedVisibleColumns && savedVisibleColumns.length) {
    visibleCols = !saveVisibleColumns
      ? columns.map((c) => c.id)
      : savedVisibleColumns;
    // keep columns that are hidden from toggling
    visibleCols = visibleCols.concat(
      columns
        .filter((c) => visibleCols.indexOf(c.id) === -1 && c.hideFromToggling)
        .map((c) => c.id),
    );
  } else if (savedHiddenColumns && savedHiddenColumns.length) {
    visibleCols = columns
      .filter((c) => savedHiddenColumns.indexOf(c.id) === -1)
      .map((c) => c.id);
  } else {
    visibleCols = defaultVisibleColumns.length
      ? columns
          .filter((c) => defaultVisibleColumns.indexOf(c.id) !== -1)
          .map((c) => c.id)
      : columns.map((c) => c.id);
  }

  const allColumnIds = columns.map((c) => c.id);
  const allColumnIdsStr = JSON.stringify(allColumnIds);

  const [hiddenCols, setHiddenCols] = useState(() => {
    return allColumnIds.filter((c) => visibleCols.indexOf(c) === -1);
  });
  useEffect(() => {
    setHiddenCols(allColumnIds.filter((c) => visibleCols.indexOf(c) === -1));
  }, [allColumnIdsStr]); // eslint-disable-line react-hooks/exhaustive-deps
  const hiddenColsStr = JSON.stringify(hiddenCols);

  const addDefaultsToColumn = useCallback(
    (column: Column<D>) => {
      const newColumn: Column<D> = { ...column };

      if (!column.accessor) {
        newColumn.accessor = (d: any) => d[column.id];
      }

      const savedColumnWidth =
        saveColumnsWidth && colWidths ? colWidths[newColumn.id] : null;

      if (savedColumnWidth) {
        newColumn.width = savedColumnWidth;
      } else if (!column.width) {
        newColumn.width = computeColumnsWidth
          ? getColumnWidth(data, newColumn.accessor, newColumn.Header)
          : 200;
      }

      if (!column.maxWidth) {
        newColumn.maxWidth = 800; // allow resize upto 800px only
      }

      return newColumn;
    },
    [computeColumnsWidth, saveColumnsWidth, data, colWidths],
  );

  const enrichedColumns = useMemo(() => {
    return columns.map((column) => addDefaultsToColumn(column));
  }, [columns, addDefaultsToColumn]);

  const initialState = useMemo(() => {
    return {
      pageSize: defaultPageSize,
      pageIndex: currentPage - 1,
      columnOrder: colOrder,
      hiddenColumns: hiddenCols,
      sortBy: savedSortedColumn ? savedSortedColumn : defaultSorted,
    };
  }, [
    defaultPageSize,
    currentPage,
    colOrder,
    hiddenCols,
    savedSortedColumn,
    defaultSorted,
  ]);

  /**
   * ==========================
   * Using React table method
   * ===========================
   */
  const {
    allColumns,
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using `rows`, we'll use `page`,  which has only the rows for the active page
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    setColumnOrder,
    setHiddenColumns,
    state: {
      pageIndex,
      pageSize,
      globalFilter,
      sortBy,
      columnOrder,
      hiddenColumns,
    },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable<D>(
    {
      columns: enrichedColumns,
      data,
      defaultColumn: {
        Cell: ({ value }: CellProps<D>) => <span>{value}</span>,
        minWidth: 100,
      },
      disableResizing,
      initialState,
      manualPagination: !!fetchData,
      pageCount: useMemo(
        () => (!!fetchData ? controlledPageCount : -1),
        [fetchData, controlledPageCount],
      ),

      manualGlobalFilter: !!fetchData,

      manualSortBy: !!fetchData,
      disableSortRemove: true,
      disableSortBy,
    },
    useBlockLayout,
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder,
  );

  // manual fetch
  useEffect(() => {
    if (fetchData) {
      fetchData({
        pageSize,
        pageIndex,
        search: globalFilter || '',
        sortBy,
      });
    }
  }, [pageSize, pageIndex, globalFilter, sortBy, fetchData]);

  // manage sort event
  const prevSortBy = useRef(sortBy);
  useEffect(() => {
    if (prevSortBy.current !== sortBy) {
      prevSortBy.current = sortBy;
      if (onSortChange && sortBy.length) {
        onSortChange(sortBy[0].id, !!sortBy[0].desc);
      }
    }
  }, [sortBy, onSortChange]);

  useEffect(() => {
    setColumnOrder(colOrder);
  }, [colOrderStr, setColumnOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHiddenColumns(hiddenCols);
  }, [hiddenColsStr, setHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHiddenCols(hiddenColumns || []);
  }, [hiddenColumns]);

  const visibleColumnIds = visibleColumns.map((c) => c.id);
  const visibleColIdsStr = JSON.stringify(visibleColumnIds);

  // used to track previous state of visible columns for each render
  const visibleColumnsRef = useRef(visibleColumnIds);

  useEffect(() => {
    if (onVisibleColumnsChange) {
      onVisibleColumnsChange(visibleColumnIds);
    }
    const prevVisible = visibleColumnsRef.current;
    if (prevVisible.length < visibleColumnIds.length) {
      // new column added
      visibleColumnIds
        .filter((col) => prevVisible.indexOf(col) === -1)
        .forEach((col) => {
          onColumnVisibilityChange && onColumnVisibilityChange(col, true);
        });
    } else if (prevVisible.length > visibleColumnIds.length) {
      // column removed
      prevVisible
        .filter((col) => visibleColumnIds.indexOf(col) === -1)
        .forEach((col) => {
          onColumnVisibilityChange && onColumnVisibilityChange(col, false);
        });
    }
    visibleColumnsRef.current = visibleColumnIds;
  }, [visibleColIdsStr, onVisibleColumnsChange]); // eslint-disable-line react-hooks/exhaustive-deps

  // watch columnPreference changes
  // save columnPreferences
  useEffect(() => {
    saveState(localStorage, `${tableId}__column_preferences`, {
      columnOrder,
      columnWidths: colWidths,
      visibleColumns: visibleColumnIds,
      sortedColumn: sortBy,
    });
  }, [tableId, columnOrder, visibleColIdsStr, sortBy, colWidths]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      saveColumnsWidth &&
      !allColumns.find((column) => column.isResizing) // save only after resizing is complete
    ) {
      let totalWidth = 0;
      allColumns.forEach((column) => {
        totalWidth += Math.round(column.totalWidth);
      });

      setTotalColumnsWidth(totalWidth);
    }
  }, [saveColumnsWidth, allColumns]);

  useEffect(() => {
    if (
      saveColumnsWidth &&
      !allColumns.find((column) => column.isResizing) // save only after resizing is complete
    ) {
      const columnsWidthMap: { [key: string]: any } = {};
      allColumns.forEach((column) => {
        columnsWidthMap[column.id] = Math.round(column.totalWidth);
      });

      setColWidths(columnsWidthMap);
    }
  }, [saveColumnsWidth, totalColumnsWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFormattedData = useCallback(() => {
    const columnsToExport = visibleColumns.filter(
      (
        column: ColumnInstance<D> & {
          disableExport?: boolean;
          headerStr?: string;
        },
      ) => !column.disableExport,
    );
    const data = [
      columnsToExport.map(
        (
          column: ColumnInstance<D> & {
            disableExport?: boolean;
            headerStr?: string;
          },
        ) => (column.headerStr ? column.headerStr : column.Header),
      ),
    ];
    rows.forEach((row) => {
      const rowData = columnsToExport.map((column) => {
        if (column.export) {
          return column.export(row.original);
        }

        return row.values[column.id];
      });

      data.push(rowData);
    });
    return data;
  }, [rows, visibleColumns]);

  const renderExport = useCallback(() => {
    if (!displayExport || isMobile) {
      return null;
    }

    if (displayExportButtonAsAction) {
      return (
        <Exporter
          id={exportButtonId}
          getData={getFormattedData}
          filename={exportFilename}
          exporting={exporting}
          onExport={onExport ? () => onExport(rows, visibleColumns) : undefined}
          useWrapper={false}
        />
      );
    }

    return (
      <TopBar>
        <Exporter
          id={exportButtonId}
          getData={getFormattedData}
          filename={exportFilename}
          exporting={exporting}
          onExport={onExport ? () => onExport(rows, visibleColumns) : undefined}
        />
      </TopBar>
    );
  }, [
    displayExport,
    isMobile,
    displayExportButtonAsAction,
    exportButtonId,
    getFormattedData,
    exportFilename,
    exporting,
    onExport,
    rows,
    visibleColumns,
  ]);

  const onSearchAction = useCallback(
    (queryString: string) => {
      if (onSearch) {
        onSearch(queryString);
      } else {
        setGlobalFilter(queryString);
      }
    },
    [onSearch, setGlobalFilter],
  );

  const [randomId] = useState(() => Math.random().toString(36).slice(2));

  /**
   * ==========================
   * Rendering the UI
   * ===========================
   */

  const TooltipColumnWrapper = ({
    children,
    column,
    target = 'infoIcon',
  }: {
    children: React.ReactNode;
    column: HeaderGroup<D>;
    target?: TooltipTarget;
  }) => {
    const hasTooltip = Boolean(column.tooltip);
    if (!hasTooltip) {
      return <>{children}</>;
    }

    const tooltipId = `table-column-tooltip-${randomId}-${column.id}`;
    const tooltip = (
      <CustomTooltip
        tooltipId={tooltipId}
        place="bottom"
        multilineHtml
        tooltip={column.tooltip}
      />
    );

    if (target === 'title') {
      return (
        <div data-for={tooltipId} data-tip="">
          {children}
          {tooltip}
        </div>
      );
    }

    return (
      <>
        {children}
        <TooltipWrapper data-for={tooltipId} data-tip="">
          <InfoIcon />
          {tooltip}
        </TooltipWrapper>
      </>
    );
  };

  return (
    <Wrapper>
      {!displayExportButtonAsAction && renderExport()}
      <Container
        noShadow={isMobile || forceNoShadow}
        noBorder={isMobile || forceNoBorder}
      >
        {!!headerComponent && (
          <HeaderComponentWrapper>{headerComponent}</HeaderComponentWrapper>
        )}
        {displayActionBar && (
          <ActionBar
            actions={actions}
            selectedPeriodComponent={selectedPeriodComponent}
            allColumns={allColumns}
            displayColumnToggler={displayColumnToggler}
            displaySearch={displaySearch}
            title={title}
            subtitle={subtitle}
            filterOptions={filterOptions}
            onSearch={onSearchAction}
            searchPlaceholder={searchPlaceholder}
            searchTestId={`${testIdPrefix}-search`}
            searchValue={globalFilter}
            setColumnOrder={setColumnOrder}
            tableId={tableId}
            totalRows={!fetchData ? preGlobalFilteredRows.length : totalRows}
            tracking={tracking}
            renderExport={
              displayExportButtonAsAction ? renderExport : undefined
            }
            searchNoPaddingHorizontal={searchNoPaddingHorizontal}
            searchFullWidth={searchFullWidth}
            searchWidth={searchWidth}
          />
        )}

        <TableContainer>
          <TableWrapper
            className={`table-wrapper ${classNamePrefix}__table_wrapper`}
            disablePointer={loading}
          >
            <StyledTable
              {...getTableProps()}
              className={`${classNamePrefix}__table`}
            >
              <THead className={`${classNamePrefix}__thead`}>
                {headerGroups.map((headerGroup) => {
                  const { key, ...groupProps } =
                    headerGroup.getHeaderGroupProps();
                  return (
                    <Tr
                      key={key}
                      {...groupProps}
                      className={`${classNamePrefix}__tr`}
                      light={light}
                    >
                      {headerGroup.headers.map((column) => {
                        const isColHighlighted = highlightColumns.includes(
                          column.id,
                        );
                        const { key, ...columnProps } = column.getHeaderProps();
                        return (
                          <Th
                            id={`${tableId}-${column.id}`}
                            key={key}
                            {...columnProps}
                            light={light}
                            className={`${classNamePrefix}__th ${classNamePrefix}__th-${column.id}`}
                            highlight={isColHighlighted}
                          >
                            <ThContent
                              {...column.getSortByToggleProps()}
                              canSort={column.canSort}
                              isSorted={column.isSorted}
                              title=""
                              className={`${classNamePrefix}__th_content`}
                              style={column.headerStyle}
                            >
                              <TooltipColumnWrapper
                                target={column.tooltipTarget}
                                column={column}
                              >
                                {column.HeaderElement ? (
                                  column.HeaderElement
                                ) : (
                                  <span>{column.render('Header')}</span>
                                )}
                              </TooltipColumnWrapper>
                              {column.isSorted && (
                                <SortIcon
                                  isDescending={column.isSortedDesc}
                                  className={`${classNamePrefix}__th_sort ${classNamePrefix}__th_sort-${
                                    column.isSortedDesc ? 'desc' : 'asc'
                                  }`}
                                >
                                  <div id="table-global-positions-arrow-up">
                                    <ArrowUpIcon
                                      color={isColHighlighted ? 'white' : ''}
                                    />
                                  </div>
                                </SortIcon>
                              )}
                            </ThContent>
                            {column.canResize && (
                              <Resizer
                                {...column.getResizerProps()}
                                isResizing={column.isResizing}
                                className={`${classNamePrefix}__th_resizer${
                                  column.isResizing ? '-active' : ''
                                }`}
                              />
                            )}
                          </Th>
                        );
                      })}
                    </Tr>
                  );
                })}
              </THead>
              <TBody
                {...getTableBodyProps()}
                className={`${classNamePrefix}__tbody`}
              >
                {page.map((row, idx) => {
                  prepareRow(row);
                  const { key, ...rowProps } = row.getRowProps();
                  return (
                    <Tr
                      key={key}
                      {...rowProps}
                      data-testid={`${testIdPrefix}-row`}
                      rowHeight={rowHeight}
                      className={`${classNamePrefix}__tr`}
                      light={light}
                      cursorPointer={onRowClick ? true : false}
                      isHighlighted={clickable && selectedRow === row.original}
                      onClick={
                        onRowClick
                          ? () => {
                              onRowClick(row.original);
                            }
                          : undefined
                      }
                    >
                      {row.cells.map((cell) => {
                        const { key, ...cellProps } = cell.getCellProps();
                        return (
                          <Td
                            key={key}
                            {...cellProps}
                            light={light}
                            className={`${classNamePrefix}__td ${classNamePrefix}__td-${cell.column.id}`}
                            highlight={highlightColumns.includes(
                              cell.column.id,
                            )}
                            noPadding={cell.column.noContentWrapper}
                          >
                            {cell.column.noContentWrapper ? (
                              cell.render('Cell')
                            ) : (
                              <TdContent
                                className={`${classNamePrefix}__td_content`}
                                style={cell.column.style}
                              >
                                {cell.render('Cell')}
                              </TdContent>
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </TBody>
            </StyledTable>
          </TableWrapper>

          {!disablePagination ? (
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={(size: number) => {
                setPageSize(size);
                if (onPageSizeChange) onPageSizeChange(size);
              }}
            />
          ) : null}

          <LoadingOverlay
            active={loading}
            className={`-loading${loading ? ' -active' : ''}`}
          >
            <span>{t('table.status.loading')}</span>
          </LoadingOverlay>

          {!loading && !page.length && (
            <EmptyState text={noDataText} button={noDataButton} />
          )}
        </TableContainer>
      </Container>
    </Wrapper>
  );
};
