import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'reducers';

export const useOnboarding = () => {
  const hasOwnedAsset = useSelector<Store>((state) => {
    return state.user.hasOwnedAsset;
  });
  const [isOnboarding, setIsOnboarding] = useState<boolean>(!hasOwnedAsset);

  useEffect(() => {
    setIsOnboarding(!hasOwnedAsset);

    return () => {
      setIsOnboarding(false);
    };
  }, [hasOwnedAsset]);

  return isOnboarding;
};
